import React from 'react';
import * as styles from './static.module.scss';
import { graphql } from "gatsby";
import Layout from "../page-components/layout/layout";
import GsHeader from "../page-components/header/gsHeader";

import Markdown from "react-markdown";

const Static = ( { data } ) => {

    const s = data.strapiStatic;

    return <Layout>

        <div className="container bg-white h-100">
            <div className="row">

                <GsHeader>
                    <h1>{s.caption}</h1>
                </GsHeader>

                <div className="col-12 py-4 px-4">
                    {s.content &&
                    <Markdown className={styles.htmlContent}
                              source={s.content.split("\n").join("<br />")}
                              allowDangerousHtml={true}/>
                    }
                </div>
            </div>
        </div>

    </Layout>

};

export default Static;

export const query = graphql`
    query staticDetailQuery($id: String!) {
        strapiStatic(id: { eq: $id }) {
              slug
              content
              caption
        }
    }      
`;
